import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Credentials</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>My Credentials</h1>
                    </header>
                    <h3>Applied Mathematics Ph.D. (In Progress) - Stony Brook University </h3>
                    <h3>Computational Mathematics B.S. - Rochester Institute of Technology</h3>
                </div>
            </section>
            <section id="two">
                <div className="inner">
                    <h1>Experience</h1>
                    <h3>Private Tutoring (4 years)</h3>
                    <ul>
                        <li>Worked with a variety of students</li>
                        <li>Calculated grades to determine feasable & satisfactory goals</li>
                        <li>Discussed resources available at the tutee's institution</li>
                    </ul>
                </div>
            </section>
            <section id="three">
                <div className="inner">
                    <h3>Professional Tutoring (6 semesters)</h3>
                    <ul>
                        <li>Professional development required</li>
                        <li>Created presentation for TutorCon</li>
                        <li>Created and led activities for finals week</li>
                    </ul>
                </div>
            </section>
            <section id="four">
                <div className="inner">
                    <h3>Supplemental Instruction Leader (3 semesters)</h3>
                    <ul>
                        <li>Weekly required trainings</li>
                        <li>Focused on student-driven learning</li>
                        <li>Earned "Outstanding SI Leader" award</li>
                    </ul>
                </div>
            </section>
            <section id="five">
                <div className="inner">
                    <h3>TA for Johns Hopkins CTY (1 summer)</h3>
                    <ul>
                        <li>Gifted children ages 14-16</li>
                        <li>Created and led recitations</li>
                        <li>Taught material not covered in lecture</li>
                    </ul>
                </div>
            </section>
            <section id="six">
                <div className="inner">
                    <h3>TA for Stony Brook (AMS) (1 semester)</h3>
                    <ul>
                        <li>Kept office hours</li>
                        <li>Answered student questions via email or zoom meetings</li>
                        <li>Recieved emails of gratitude from happy students</li>
                    </ul>
                </div>
            </section>
            <section id="seven">
                <div className="inner">
                    <h3>TA for Stony Brook (MAT) (current - less than 1 semester)</h3>
                    <ul>
                        <li>Weekly required trainings</li>
                        <li>Led recitations</li>
                        <li>Office hours & email responses still required</li>
                    </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
